import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAttibute } from 'src/app/views/pages/contact-setting/models/custom-attibute.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkflowsService } from '../../workflows/services/workflows.service';
import { Subscription } from 'rxjs';
import { ContactsService } from '../../contact-management/services/contacts.service';
import { CustomAttributesService } from '../services/custom-attributes.service';
import { CompaniesService } from '../../company-management/services/companies.service';
import { UsersService } from '../../users-management/Services/users.service';
import { AdvanceSearchService } from './services/advanced-search.service';
import { CustomAttributeTypeEnum } from '../constant/CustomAttributeTypeEnum';
import { FieldService } from '../services/field.service';
import { AttributeType } from '../../contact-setting/models/attribute-type.model';
import { SystemAttributeEnum } from '../constant/SystemAttributeEnum.constants';

@Component({
  selector: 'kt-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  moduleType: any;
  advancedSearchForm: FormGroup;
  numericBetween: boolean = false;
  dateBetween: boolean = false;
  rowData = [];
  lastRowIndex: number = 0;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  operatorListMap: Map<number, { key: number, value: string }[]> = new Map();
  operatorList: { key: number, value: string }[];
  customerEntityFieldList: CustomAttibute[] = null;
  opportunityEntityFieldList: CustomAttibute[] = null;
  usersFieldList: CustomAttibute[] = null;
  productsFieldList: CustomAttibute[] = null;
  contactEntityFieldList: CustomAttibute[] = null;
  subscriptions: Subscription[] = [];
  currentAttributeTypeId: number = 6;
  allFieldData = [];
  filteredData = [];
  restrictedSystemIds = [15, 16, 21,59,60];
  restrictedAttributeIds = [4, 5, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
  disabled: boolean = false;
  teamFlag: boolean;
  opportunityView: number;
  contactView:number;
  customerView: number;
  gridViewFlag: boolean = false;
  selectedFormArrayEntitiesAndDataList: Array<{
    numericbetween: boolean; entityId: number, attributeTypeId: number, operatorList: { key: number, value: string }[], fieldValues: { id: number; name: string; }[], dateBetween: boolean
  }> = [];
  public advanceSearchArrayData=[];
  public totalDataCount = 0;
  pageSize: number = 10000;
  pageNumber: number = 1;
  isFirstAdvanceSearch: boolean = true;
 

  constructor(private formBuilder: FormBuilder,
    private _as: AdvanceSearchService,
    private _field: FieldService,
    public activeModal: NgbActiveModal,
    private SpinnerService: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef) {
    this.advancedSearchForm = this.formBuilder.group({
      rows: this.formBuilder.array([])
    });
    this.selectedFormArrayEntitiesAndDataList = [];

  }

  ngOnInit(): void {
    this.setEntityFieldLists(this.moduleType);
  }


  addNewRow() {
    this.selectedFormArrayEntitiesAndDataList.push({ entityId: null, attributeTypeId: 6, operatorList: [], fieldValues: [], numericbetween: false, dateBetween: false });
    const newRow = this.formBuilder.group({
      entityfield: ['', Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required],
      fromvalue: ['',Validators.required ],
      tovalue: ['',Validators.required]
    });
    this.rowsArray.push(newRow);
    this.lastRowIndex = this.rowsArray.controls.length - 1;
  }


  get rowsArray() {
    return (this.advancedSearchForm.get('rows') as FormArray);
  }



  isControlHasError(controlName: string, validationType: string, index: number): boolean {
    const control = this.rowsArray.at(index).get(controlName);
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }


  closeModal() {
    this.activeModal.close();
  }

  changeEntityField(attribute: CustomAttibute, rowIndex: number,isEdit:boolean=false) {
    if(isEdit){
      this.setFormControlValuesBasedOnEditData(rowIndex, attribute);
    }else{
      this.rowsArray.at(rowIndex).get('operator').reset();
      this.rowsArray.at(rowIndex).get('value').reset();
      this.rowsArray.at(rowIndex).get('fromvalue').reset();
      this.rowsArray.at(rowIndex).get('tovalue').reset();
    }
    this.selectedFormArrayEntitiesAndDataList[rowIndex].attributeTypeId = attribute.attributeType.id;
    this.selectedFormArrayEntitiesAndDataList[rowIndex].operatorList = this.operatorListMap.get(attribute.attributeType.id);
    if (attribute.systemAttribute?.id == 11 || attribute.systemAttribute?.id == 5) {
      this._as.getCompanyIdName(this.selectedProject, this.moduleType, 3).subscribe(
        data => {
          if(data['statusCode']>=400){
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = data['responseData']
        })
    }
    else if (attribute.systemAttribute?.id == 6) {
      this._as.getAllContactDetailsForSelfAndTeam(this.selectedProject, this.moduleType).subscribe(
        data => {
          if(data['statusCode']>=400){
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getContactDetails(data['responseData']);
        })
    }
    else if (attribute.systemAttribute?.id == 34 || attribute.systemAttribute?.id == 35 || attribute.systemAttribute?.id == 65) {
      this._as.getOwner(this.selectedProject, Number(this.moduleType)).subscribe(
        data => {
          if(data['statusCode']>=400){
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getOwnerDetails(data['responseData']);
        })
    }
    else if (attribute.systemAttribute?.id == 57) {
      this._as.getUserRole(this.selectedProject).subscribe(
        result => {
          if (+result['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getUserRoleDetails(result['responseData']);
        })
    }
    else if (attribute.systemAttribute?.id == 58) {
      this._as.getAllUsers(this.selectedProject).subscribe(
        result => {
          if(result['statusCode']>=400){
            this.showErrorSnackBarAndClose(false);
            return;
          }
          this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getAllUsersDetails(result['responseData'].fieldValues);
        }
      )
    }
    else {
      this.selectedFormArrayEntitiesAndDataList[rowIndex].fieldValues = this.getFieldValues(attribute);
    }
  }

  private setFormControlValuesBasedOnEditData(rowIndex: number, attribute: CustomAttibute) {
    this.rowsArray.at(rowIndex).get('entityfield').setValue(attribute);
    this.rowsArray.at(rowIndex).get('operator').setValue(this.advanceSearchArrayData[rowIndex].operatorId);
    if ((attribute.attributeType.id == CustomAttributeTypeEnum.SINGLE_SELECT_DROPDOWN) || (attribute.attributeType.id == CustomAttributeTypeEnum.MULTI_SELECT_DROPDOWN) || (attribute.attributeType.id == CustomAttributeTypeEnum.DATA_LIST)) {
      let array = this.advanceSearchArrayData[rowIndex].value.split(",").map(Number);
      this.rowsArray.at(rowIndex).get('value').setValue(array);
      this.rowsArray.at(rowIndex).get('fromvalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
    }
    else if ((attribute.attributeType.id == CustomAttributeTypeEnum.NUMERIC || attribute.attributeType.id == CustomAttributeTypeEnum.DATE) && this.advanceSearchArrayData[rowIndex].operatorId == 5) {
      this.changeOperator(this.advanceSearchArrayData[rowIndex].operatorId, rowIndex);
      this.rowsArray.at(rowIndex).get('fromvalue').setValue(this.advanceSearchArrayData[rowIndex].fromValue);
      this.rowsArray.at(rowIndex).get('tovalue').setValue(this.advanceSearchArrayData[rowIndex].toValue);

    } else {
      this.rowsArray.at(rowIndex).get('fromvalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('value').setValue(this.advanceSearchArrayData[rowIndex].value);

    }
  }

  getFieldValues(attribute: CustomAttibute) {
    let fieldValues: { id: number; name: string; }[] = [];
    if((attribute.attributeType.id ==1 ||attribute.attributeType.id==2) &&((!(attribute.lookupValues)||(attribute.lookupValues?.length==0)))){
      this._field.getAttribLookup(attribute.id).subscribe(data => {
        
        data['responseData'].forEach(res => {
          fieldValues.push({ id: res.id, name: res.lookupValue })
        });
        attribute.lookupValues=data['responseData'];
      })
    }else if(attribute.attributeType.id ==1 &&(attribute.lookupValues?.length>0) ){
      attribute.lookupValues.forEach(res => {
        fieldValues.push({ id: res.id, name: res.lookupValue })
      })
    }

    
    return fieldValues;
  }
  getOwnerDetails(ownerList: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    ownerList.forEach(res => {
      fieldValues.push({ id: res.id, name: res.fullName })
    })
    return fieldValues;
  }
  getContactDetails(contactList: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    contactList.forEach(res => {
      fieldValues.push({ id: res.contactId, name: res.contactName })
    })
    return fieldValues;
  }
  getUserRoleDetails(usersRole: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    usersRole.forEach(res => {
      fieldValues.push({ id: res.roleId, name: res.roleName })
    })
    return fieldValues;
  }

  getAllUsersDetails(allUsersList: any) {
    let fieldValues: { id: number; name: string; }[] = [];
    allUsersList.forEach(res => {
      fieldValues.push({ id: res[0], name: res[1] })
    })
    return fieldValues;
  }


  changeOperator(value, rowIndex: number) {
    if (this.selectedFormArrayEntitiesAndDataList[rowIndex].attributeTypeId == 3) {
      this.dateBetween = value == 5 ? true : false;
    } else if (this.selectedFormArrayEntitiesAndDataList[rowIndex].attributeTypeId == 7) {
      this.numericBetween = value == 5 ? true : false;
    }
    if (value == 5) {
      this.rowsArray.at(rowIndex).get('value').clearValidators();
      this.rowsArray.at(rowIndex).get('value').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('fromvalue').setValidators([Validators.required]);
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('tovalue').setValidators([Validators.required]);
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
    } else {
      this.rowsArray.at(rowIndex).get('fromvalue').clearValidators();
      this.rowsArray.at(rowIndex).get('fromvalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('tovalue').clearValidators();
      this.rowsArray.at(rowIndex).get('tovalue').updateValueAndValidity();
      this.rowsArray.at(rowIndex).get('value').setValidators([Validators.required]);
      this.rowsArray.at(rowIndex).get('value').updateValueAndValidity();
    }
  }




  getOperators() {
    this.SpinnerService.show('fetchFields');
    this.subscriptions.push(
      this._as.getAdvanceSearchOperators().subscribe(res => {
        if(res['statusCode']>=400){
          this.showErrorSnackBarAndClose(false);
          return;
        }
        (<Array<any>>res['responseData']).forEach(x => {
          this.operatorListMap.set(x.attributeType, x.operators);
        });
      },
      error =>{
        this.showErrorSnackBarAndClose(false);
      })
    );
  }


  setEntityFieldLists(entityId: number) {
    let responseData: CustomAttibute[] = null;
    this.SpinnerService.show('fetchFields');

    
    this._as.getAdvanceSearchOperators().subscribe(res => {
      if(res['statusCode']>=400){
        this.showErrorSnackBarAndClose(false);
        return;
      }
      (<Array<any>>res['responseData']).forEach(x => {
        this.operatorListMap.set(x.attributeType, x.operators);
      });

      // Get field data
      this._as.getFields(this.moduleType, this.selectedProject).subscribe(res => {
     
        if (res['statusCode'] >= 400) {
          this.showErrorSnackBarAndClose(false);
          return;
        }
  
        
        
        responseData = res['responseData'].filter(x => {
          return x.status === 1 &&
            !(this.restrictedSystemIds.includes(x.systemAttribute?.id) ||
              this.restrictedAttributeIds.includes(x.attributeType.id))
        });
        if (entityId == 1) {
          this.contactEntityFieldList = responseData;
        }
        else if (entityId == 2) {
          this.customerEntityFieldList = responseData;
          const compSerialKeyAttrib:CustomAttibute=new CustomAttibute();
          compSerialKeyAttrib.id=SystemAttributeEnum.COMPANY_SERIAL_KEY_ID;
          compSerialKeyAttrib.attributeType=new AttributeType(CustomAttributeTypeEnum.TEXT_SINGLE_LINE,null);
          compSerialKeyAttrib.attributeName="Serial Key"
          this.customerEntityFieldList.push(compSerialKeyAttrib);
          this.customerEntityFieldList.sort((a, b) => {
            if (a.id < 0 && b.id < 0) {
                return a.id - b.id; // Sort negative ids in ascending order
            }
            if (a.id < 0) {
                return -1; // a is negative, b is positive: a comes first
            }
            if (b.id < 0) {
                return 1; // b is negative, a is positive: b comes first
            }
            // Both a and b are positive, sort by displayOrder
            return a.displayOrder - b.displayOrder;
        });
        }
        else if (entityId == 3) {
          this.opportunityEntityFieldList = responseData;
          const oppSerialKeyAttrib:CustomAttibute=new CustomAttibute();
          oppSerialKeyAttrib.id=SystemAttributeEnum.OPPORTUNITY_SERIAL_KEY_ID;
          oppSerialKeyAttrib.attributeType=new AttributeType(CustomAttributeTypeEnum.TEXT_SINGLE_LINE,null);
          oppSerialKeyAttrib.attributeName="Serial Key"
          this.opportunityEntityFieldList.push(oppSerialKeyAttrib);
          this.opportunityEntityFieldList.sort((a, b) => {
            if (a.id < 0 && b.id < 0) {
                return a.id - b.id; // Sort negative ids in ascending order
            }
            if (a.id < 0) {
                return -1; // a is negative, b is positive: a comes first
            }
            if (b.id < 0) {
                return 1; // b is negative, a is positive: b comes first
            }
            // Both a and b are positive, sort by displayOrder
            return a.displayOrder - b.displayOrder;
        });
          
        }
        else if (entityId == 4) {
          this.productsFieldList = responseData;
        }
        else if (entityId == 5) {
          this.usersFieldList = responseData;
        }
        if(this.advanceSearchArrayData.length>0){
          this.advanceSearchArrayData.forEach((r,index)=>{
            this.addNewRow();
            this.selectedFormArrayEntitiesAndDataList[index].entityId=r.entityFieldId;
            this.selectedFormArrayEntitiesAndDataList[index].attributeTypeId=r.attributeTypeId;
            this.changeEntityField(responseData.find(a=>a.id==r.entityFieldId),index,true);
          })
        }else{
          this.addNewRow();
        }
       
        this.SpinnerService.hide('fetchFields');
      },
        error => {
          this.showErrorSnackBarAndClose(false);
        });

    },
    error =>{
      this.showErrorSnackBarAndClose(false);
    });
  }
  showErrorSnackBarAndClose(close: boolean = true) {
    this.SpinnerService.hide('fetchFields');
    this.snackBar.open('Unable to fetch data. Please try again.', '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
    if (close) {
      this.activeModal.close();
    }
  }

  keyPressNumbersWithDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  clearForm() {
    this.disabled = false;
    this.rowsArray.controls.forEach((formGroup: FormGroup) => {
      Object.keys(formGroup.controls).forEach(controlName => {
        formGroup.get(controlName).reset();
      });
    });
  }

  removeRow(index: number) {
    this.rowsArray.removeAt(index);
    this.selectedFormArrayEntitiesAndDataList.splice(index, 1);
    if (this.lastRowIndex === this.rowsArray.controls.length) {
      this.lastRowIndex = this.rowsArray.controls.length - 1;
    }
  }
  onApplyFilters() {
    const formData = this.advancedSearchForm.getRawValue();
    const rowsArray = formData.rows as any[];
    this.rowsArray.controls.forEach((formGroup: FormGroup) => {
      Object.keys(formGroup.controls).forEach(controlName => {
        formGroup.get(controlName).markAsTouched();
      });
    });
    if(!this.advancedSearchForm.valid){
      return;
    }
    let advanceSearchArray: any[] = [];
    for (const row of rowsArray) {
      if (row.entityfield.attributeType.id == 1 || row.entityfield.attributeType.id == 2 ||
        row.entityfield.attributeType.id == 18) {
        row.value = row.value ? row.value.join(',') : null;
      } else if (row.entityfield.attributeType.id == 3){
        if(row.operator==5){
          let toDate = new Date(row.tovalue);
          toDate.setDate(toDate.getDate() + 1);
          row.tovalue = toDate.toISOString().split('T')[0];
          let fromDate = new Date(row.fromvalue);
          fromDate.setDate(fromDate.getDate() + 1);
          row.fromvalue = fromDate.toISOString().split('T')[0];
        }else{
          let originalDate = new Date(row.value);
          originalDate.setDate(originalDate.getDate() + 1);
          row.value = originalDate.toISOString().split('T')[0];
        }
        
      }
       else {
        row.value = row.value ? row.value : null;
      }
      const advanceSearhFilter = {
        "entityFieldId": row.entityfield.id,
        "attributeTypeId": row.entityfield.attributeType.id,
        "operatorId": row.operator,
        "value": row.value,
        "fromValue": row.fromvalue ? row.fromvalue : null,
        "toValue": row.tovalue ? row.tovalue : null,
      }
      advanceSearchArray.push(advanceSearhFilter);

    }
    if (this.moduleType == 1) {
      this._as.getContactFilter(this.selectedProject, this.contactView, advanceSearchArray).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
            this.filteredData = data['responseData'];
            this.activeModal.close(this.filteredData);
        },error => {
          this.showErrorSnackBarAndClose();
        });
    }
    else if (this.moduleType == 2) {
      const advanceSearchrequest = {
        "advanceSearch": advanceSearchArray,
        "isFirstAdvSearch":true
      }
      this._as.getCompanyFilter(this.selectedProject, this.customerView,+0,+10000, advanceSearchrequest).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
            this.filteredData = data['responseData'].data;
            const response={advanceSearchArray:advanceSearchArray,
            filteredData:this.filteredData,
            totalCount:data['responseData'].totalCountForAdvSearch}
            this.activeModal.close(response);
        },error => {
          this.showErrorSnackBarAndClose()});
    }
    if (this.moduleType == 3) {
      if (!this.gridViewFlag) {
        const advanceSearchRequest = {
          "advanceSearch": advanceSearchArray,
          "isFirstAdvSearch":true
        }
        this._as.getOpportunitiesFilter(this.selectedProject, this.opportunityView,+0,+10000, advanceSearchRequest).subscribe(
          data => {
            if (data['statusCode'] >= 400) {
              this.showErrorSnackBarAndClose();
              return;
            }
            this.filteredData = data['responseData'].data;
            const response={advanceSearchArray:advanceSearchArray,
            filteredData:this.filteredData,
            totalCount:data['responseData'].totalCountForAdvSearch}
            this.activeModal.close(response);
          },error => {
            this.showErrorSnackBarAndClose()});
      } else {
        this._as.getOpportunitiesGridViewFilter(this.selectedProject, this.opportunityView, advanceSearchArray).subscribe(
          data => {
            if (data['statusCode'] >= 400) {
              this.showErrorSnackBarAndClose();
              return;
            }
              this.filteredData = data['responseData'];
              this.activeModal.close(this.filteredData);
          },error => {
            this.showErrorSnackBarAndClose()});
      }

    }
    if (this.moduleType == 5) {
      this._as.getUserFilter(this.selectedProject, this.pageSize, this.pageNumber++, this.isFirstAdvanceSearch, advanceSearchArray).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
            this.filteredData = data['responseData'];
            this.activeModal.close({
              filteredData: this.filteredData, 
              advanceSearchArray: advanceSearchArray
            });
        },error => {
          this.showErrorSnackBarAndClose()});
    }
    if (this.moduleType == 4) {
      this._as.getProductFilter(this.selectedProject, advanceSearchArray).subscribe(
        data => {
          if (data['statusCode'] >= 400) {
            this.showErrorSnackBarAndClose();
            return;
          }
            this.filteredData = data['responseData'];
            this.activeModal.close(this.filteredData);
        },error => {
          this.showErrorSnackBarAndClose()});
    }
    this.disabled = true;


  }


}
